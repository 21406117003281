.clickable:hover
{
  cursor: pointer;
}

body 
{
  background-color: black;
  color: white;
  font-family: "Nunito", sans-serif;
}

.nav-color
{
  background: black;
}

.content-block
{
  background: #26262b;
}

.cdcPre {
  color: #cdc1ad;
}

.footer
{
  background: #26262b;
}

.MuiFormHelperText-root 
{
  color: #783abc;
}